import { CustomerShort } from "./customers";
import { StoreShort } from "./stores";
import { Media } from "./general";

//EXCLUSION RULES
export interface ExclusionRule {
  name: string;
  id: number;
  type: ExclusionRuleType;
  store?: StoreShort;
  customers: CustomerShort[];
  invoice_type: XeroInvoiceType;
}

export interface ExclusionRuleManage {
  name: string;
  type: ExclusionRuleType;
  related_entity: ExclusionRuleEntity;
  related_conditions: [1, 2, 3];
  store_id?: number;
  invoice_type: XeroInvoiceType;
}

export enum ExclusionRuleEntity {
  CUSTOMERS = "customers",
}

export enum ExclusionRuleType {
  EXCLUDE_CUSTOMERS = "exclude_customers",
  INCLUDE_CUSTOMER = "include_customers",
}

//SCHEDULE TEMPLATES
export interface ScheduleTemplateManage {
  type: string;
  invoice_type: XeroInvoiceType;
  day: string;
  time: string;
  condition: string;
  order_types: string[];
}
export interface ScheduleTemplate {
  id?: number;
  user: {
    id: number;
    name: string;
    image: Media;
  };
  store: any;
  type: string;
  invoice_type: XeroInvoiceType;
  day: string;
  time: string;
  condition: string;
  order_types: string[];
}

//XERO
export interface Xero {
  connected: boolean;
  error: any;
  username: string;
  tenants: {
    Id: string;
    Name: string;
    location_id?: number;
    brand_id?: number;
    invoices?: {
      account?: XeroAccountSettings;
      integration_settings?: XeroIntegrationSettings;
      account_mapping?: XeroAccountMappingSettings;
    };
    bills?: {
      account?: XeroAccountSettings;
      integration_settings?: XeroIntegrationSettings;
      account_mapping?: XeroAccountMappingSettings;
    };
  }[];
}

export interface XeroOrganization {
  tenant_id: string;
  location_id?: number;
  brand_id?: number;
  invoices?: {
    account: XeroAccountSettings;
    integration_settings?: XeroIntegrationSettings;
    account_mapping?: XeroAccountMappingSettings;
  };
  bills?: {
    account: XeroAccountSettings;
    integration_settings?: XeroIntegrationSettings;
    account_mapping?: XeroAccountMappingSettings;
  };
}

export interface XeroAssociatedLocation {
  tenant_id: string;
  location_id?: number;
  invoices: {
    account: XeroAccountSettings;
    integration_settings: XeroIntegrationSettings;
    account_mapping?: XeroAccountMappingSettings;
  };
  bills: {
    account: XeroAccountSettings;
    integration_settings: XeroIntegrationSettings;
    account_mapping?: XeroAccountMappingSettings;
  };
}

export interface LatestXeroInvoice {
  id: number;
  order: number;
  invoice_id: string;
  invoice_number: string;
  invoice_type: string;
  invoice_status: string;
  currency_code: string;
  status: string;
  errors: any;
}

export interface XeroAccount {
  code: string;
  name: string;
  type: string;
  description: string;
  id: string;
}

export interface XeroAccountSettings {
  sequence_number?: number;
  invoice_prefix?: string;
  draft_status: boolean;
  inventory_item: boolean;
  order_with_zero_totals: boolean;
  use_alphabetical_order: boolean;
}

export interface XeroIntegrationSettings {
  invoices: string;
  payments?: string;
  shipping?: string;
  fees?: string;
}

export interface XeroAccountMappingSettings {
  sales?: string;
  shipping?: string;
  fees?: string;
  payments?: string;
  paymnets?: string;
  rounding?: string;
}

export enum XeroLevel {
  ACCOUNT_LEVEL = "account",
  MULTI_ACCOUNT_LEVEL = "multi_account",
}

export enum XeroInvoiceType {
  BILL = "bill",
  INVOICE = "invoice",
}

export enum XeroInvoicesAndPaymentsTypes {
  MANUALLY = "manually",
  ON_ORDER_CREATION = "creation",
  ON_ORDER_COMPLETION = "completion",
  DISABLED = "disabled",
  PAYMENT_SYNC = "payment_sync",
  ORDER = "order"
}

export enum InvoiceIssueDateTypes {
  SYNCED_DATE ="synced_date",
  ORDER_CREATING_DATE ="order_creating_date",
  TARGET_DATE ="target_date",
}

export enum XeroShippingAndFeesTypes {
  INCOME_REVENUE_SALES = "income_revenue_sales",
  EXPENSES = "expenses",
}
